import { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import RTOHometowneCard from '../components/partnerCards/RTOHometowne';
import RTOHopkinsCard from '../components/partnerCards/RTOHopkins';
import TFKoalaCard from '../components/partnerCards/TFKoala';
import TFRockSolidFundingCard from '../components/partnerCards/TFRockSolidFunding';
import TFTrailerSolutionsFinancialCard from '../components/partnerCards/TFTrailerSolutionsFinancial';
import TFClickLeaseCard from '../components/partnerCards/TFClickLease';
import RTOBCCapitalCard from '../components/partnerCards/RTOBCCapital';


const rtoDetails = [
  "NO CREDIT CHECKS!!!",
  "Pay a low monthly rate until your trailer is yours to keep!",
  "Quickest way to get a trailer in your driveway!",
  "Contact us for more information",
]

const tfDetails = [
  "Finance with a traditional bank loan or lease"
]

const cashDetails = [
  "Pay in full with cash or credit card",
  "All major cards accepted",
  "Transactions processed using Square©",
]

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const Financing = () => {
  const [value, setValue] = useState(0);

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className='financing-component'>
      <h2>Financing options to achieve your goals</h2>
      <div id="financing-options">
        <Box
        id="financing-options"
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          maxWidth: { sm: "100%", md: "85%" }
        }}>
          <Tabs
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          aria-label=""
          centered="true"
          TabIndicatorProps={{
            style: {
              height: 3,
              backgroundColor: "gold",
            }
          }}>
            <Tab label="Rent To Own" />
            <Tab label="Traditional Finance" />
            <Tab label="Cash" />
          </Tabs>
        </Box>

        {/* RTO FINANCING */}
        <TabPanel value={value} index={0}>
          <ul>
            {rtoDetails.map((detail, index) => (
              <li key={index}>
                {detail}
              </li>
            ))}
          </ul>

          <div id="finance-app">
            <Typography
            variant='h3'
            sx={{
              marginTop: '0.5em',
              marginBottom: '0.2em'
            }}>
              - Partners -
            </Typography>

            <RTOHometowneCard />
            <RTOHopkinsCard />
            <RTOBCCapitalCard />
            
          </div>
        </TabPanel>

        {/* TRADITIONAL FINANCING */}
        <TabPanel value={value} index={1}>
          <ul>
            {tfDetails.map((detail, index) => (
              <li key={index}>
                {detail}
              </li>
            ))}
          </ul>

          <div id="finance-app">
            <Typography
              variant='h3'
              sx={{
                marginTop: '0.5em',
                marginBottom: '0.2em'
              }}>
                - Partners -
              </Typography>

            <TFClickLeaseCard />
              
            <TFKoalaCard />

            <TFRockSolidFundingCard />

            <TFTrailerSolutionsFinancialCard />
          </div>
        </TabPanel>

        {/* CASH FINANCING */}
        <TabPanel value={value} index={2}>
          <ul>
            {cashDetails.map((detail, index) => (
              <li key={index}>
                {detail}
              </li>
            ))}
          </ul>
        </TabPanel>
      </div>
      
    </div>
  )
}

export default Financing;