import { useMemo, useState } from "react"

export const Home = () => {
  const [homeImg, setImg] = useState(null);

  useMemo(() => {
    setImg("https://pcs-trailer-products.s3.amazonaws.com/General/cropped_trailers_resized.jpg")
  }, [])

  return (
    <div className="home-component">
      <div id="main-image">
        <img
        id="home-trailer-image"
        src={homeImg}
        alt='row of cargo trailers'>
        </img>
      </div>
      
      <div id="about-us">
        <h1>About Us</h1>
        <p>
          Located in the heart of Douglas, GA, we work to get you the <b>best quality</b> trailers for the <b>best price</b>.
        </p>
        <p>
          We source from <b>over a dozen</b> manufacturers so you can purchase the trailer of your dreams with a variety of financing options.
        </p>
        <p>
          Contact us today so we can show you what we mean!
        </p>
      </div>
    </div>
  )
}

export default Home