import React from 'react'
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import PhoneIcon from '@mui/icons-material/Phone';
import SearchIcon from '@mui/icons-material/Search';

export const Ordering = () => {
  return (
    <div className='ordering-component'>
      <Box 
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center">
        <h2>How to Order</h2>
        <List >
            <ListItem>
            </ListItem>
            <ListItem>
              <ListItemButton>
                <ListItemIcon>
                  <SearchIcon />
                </ListItemIcon>
                <ListItemText primary="Browse Our Product Page For Ideas!" />
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton>
                <ListItemIcon>
                  <DriveEtaIcon />
                </ListItemIcon>
                <ListItemText primary="If The Trailer Is Available, Come On By! If Not, We Can Custom Order Down To The Last Screw!" />
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton>
                <ListItemIcon>
                  <AttachMoneyIcon />
                </ListItemIcon>
                <ListItemText primary="Determine How You Will Finance" />
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton>
                <ListItemIcon>
                  <PhoneIcon />
                </ListItemIcon>
                <ListItemText primary="Contact Us By Email, Phone, or Text To Get Your Quote Started!" />
              </ListItemButton>
            </ListItem>
        </List>
      </Box>
    </div>
  )
}

export default Ordering;