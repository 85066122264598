import Grid from '@mui/material/Grid';
import ProductCard from '../components/ProductCard';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import productData from '../api/productData';
import concessionProductData from '../api/concessionProductData';
import carProductData from '../api/carProductData';
import { Typography } from '@mui/material';


export const Product = () => {
  const [productImages, loadData] = useState(null);
  const [concessionProductImages, loadConcessionProductData] = useState(null);
  const [carProductImages, loadCarProductData] = useState(null);

  useEffect(() => {
    if (productImages === null) {
      loadData(productData);
    }
    if (concessionProductImages === null) {
      loadConcessionProductData(concessionProductData);
    }
    if (carProductImages === null) {
      loadCarProductData(carProductData);
    }
  }, [productImages, concessionProductImages, carProductImages])

  if (productImages === null || concessionProductImages === null) {
    return (
      <Box sx={{
        display: 'flex',
        padding: '3em',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <CircularProgress 
          size={'8em'}
        />
      </Box>
    )
  }

  return (
    <div className='product-component'>
      <Paper
      className="product-intro" 
      square
      elevation={0}
      sx={{
        backgroundColor: "beige",
        maxWidth: 400,
        margin: "1em"
      }}>
        
        <Typography variant='h6'
        sx={{
          maxWidth: 400,
          fontWeight: 750,
        }}>
        We are <u>uniquely</u> able to customize your trailer with:
        <ul id="product-intro-list">
          <li>Any Color</li>
          <li>SA/TA/TrA Axles</li>
          <li>Exterior/Interior Accessories</li>
          <li>Power Supplies</li>
          <li>And more!</li>
        </ul>
        </Typography>
      </Paper>

      <Typography variant='h3'
        sx={{
          maxWidth: 400,
          fontWeight: 750,
        }}>
        Best Sellers
      </Typography>
      <Grid 
      container
      direction="row"
      padding={"1.5em 0 1.5em"}
      spacing={{ xs: 4, md: 4 }} 
      columns={{xs: 4, sm: 8, md: 8}}
      className="product-grid"
      >
        {
          concessionProductData.map((item, index) => (
            <Grid item xs={4} sm={4} md={4} align="center" key={index}>
              <ProductCard data={item} />
            </Grid>
          ))
        }

        {
          carProductData.map((item, index) => (
            <Grid item xs={4} sm={4} md={4} align="center" key={index}>
              <ProductCard data={item} />
            </Grid>
          ))
        }
      </Grid>

      <Typography variant='h3'
        sx={{
          maxWidth: 400,
          fontWeight: 750,
        }}>
        By Width
      </Typography>
      <Grid 
      container
      direction="row"
      padding={"1.5em 0 5em"}
      spacing={{ xs: 4, md: 4 }} 
      columns={{xs: 4, sm: 8, md: 8}}
      className="product-grid"
      >
        {
          productImages.map((item, index) => (
            <Grid item xs={4} sm={4} md={4} align="center" key={index}>
              <ProductCard data={item} />
            </Grid>
          ))
        }
      </Grid>

      <Typography variant='h4'
        sx={{
          maxWidth: 400,
          fontWeight: 750,
          textAlign: "center"
        }}>
        Still Not Seeing What You Want?<br /><br /> Call Us And Let Us Know!!
      </Typography>
    </div>
  )
}

export default Product;