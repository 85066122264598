import { useState, useEffect, useRef } from 'react'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { NavLink, useLocation } from "react-router-dom";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';


// Remove annoying warnings
const DividerOverride = (ParentProps) => {
  const divProps = Object.assign({}, ParentProps);
  delete divProps.fullWidth;
  delete divProps.selectionFollowsFocus;
  delete divProps.textColor;
  delete divProps.indicator;
  return <Divider {...divProps} />
}

export const Navbar = () => {
  const location = useLocation();

  // A bit jank but works
  const routes = [
    "",
    "/",
    "",
    ["/financing", "/ordering"],
    "",
    "/products",
    "",
  ]

  // eslint-disable-next-line
  const currentTabVal = () => {
    let path = location.pathname;
    for (const x of [...Array(routes.length).keys()]) {
      if (path === routes[x] || routes[x].includes(path) ) {
        return x
      }
    }
  }
  const [localSelection, setValue] = useState(currentTabVal);
  useEffect(() => {
    setValue(currentTabVal);
  }, [currentTabVal])

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  const anchorRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorSize, setAnchorSize] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);


  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setAnchorSize(anchorRef.current.offsetWidth)
    setMenuOpen(true);
  }
  const handleClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  }

  return (
    <div>
        <Box sx={{
          borderBottom: 2,
          borderTop: 2,
          borderColor: 'divider',
          backgroundColor: "gold"
          }}>
            <Tabs
            variant="fullWidth"
            value={localSelection}
            onChange={handleChange}
            aria-label="Navigation"
            TabIndicatorProps={{
              style: {
                height: 3,
                backgroundColor: "blue",
              }
            }}
            >
              <DividerOverride orientation="vertical" flexItem />
              <Tab
              label="Home"
              component={NavLink}
              to={"/"}
              sx={{
                fontSize: "1em",
                fontWeight: 750,
                height: "1em",
                textTransform: "none"
              }}/>
              <DividerOverride orientation="vertical" flexItem />
              <Tab
              ref={anchorRef}
              label="How It Works"
              component={NavLink} 
              sx={{
                fontSize: "1em",
                fontWeight: 750,
                height: "1em",
                textTransform: "none"
              }}
              onMouseDown={handleOpen}/>
              <DividerOverride orientation="vertical" flexItem />
              <Tab 
              label="Products"
              component={NavLink}
              to={"/products"}
              sx={{
                fontSize: "1em",
                fontWeight: 750,
                height: "1em",
                textTransform: "none"
              }}/>
              <DividerOverride orientation="vertical" flexItem />
              {/* <Tab
              label="Gallery"
              component={NavLink}
              to={"/gallery"}/>
              <DividerOverride orientation="vertical" flexItem /> */}
            </Tabs>

            {/* Menu dropdown for Tab */}
            <Menu
            id="menu"
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={handleClose}
            MenuListProps={{              
              'aria-labelledby': 'basic-tab'
            }}
            >
              <MenuItem
                sx={{
                  width: anchorSize,
                  backgroundColor: "beige"
                }}
                onClick={handleClose}
                component={NavLink}
                to={"/financing"}
                divider={true}
              >Financing
              </MenuItem>
              <MenuItem 
                  sx={{
                    width: anchorSize,
                    backgroundColor: "beige"
                  }}
                  onClick={handleClose}
                  component={NavLink}
                  to={"/ordering"}
              >Ordering
              </MenuItem>
            </Menu>
        </Box>
    </div>
  )
}

export default Navbar;