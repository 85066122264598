import { useState } from 'react'
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailModal from './modal/EmailModal';
import Divider from '@mui/material/Divider';
import { Button, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

// Remove annoying warnings
const DividerOverride = (ParentProps) => {
  const divProps = Object.assign({}, ParentProps);
  delete divProps.showLabel;
  return <Divider {...divProps} />
}

export function Footer() {
    const [footerVal, setFooterValue] = useState(null);
    const [emailModalStatus, setEmailModalStatus] = useState(false);
    const handleEmailOpen = () => setEmailModalStatus(true);
    const handleEmailClose = () => setEmailModalStatus(false);
    const openFacebook = () => {
      window.open("https://www.facebook.com/profile.php?id=100090383066331");
    }
    const beginCall = () => {
      window.location.href="tel:+19123931894"
    }
    const linkToMaps = () => {
      window.open("https://goo.gl/maps/v7rmMZTJwGiTZQ828");
    }
  
    return (
      <div>
        <Box sx={{
          borderTop: 1,
          backgroundColor: "beige",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "3.6em"
        }}>

          <table id="information-table">
            <tbody>
              <tr>
                <td id="table-cell-label">Hours:</td>
                <td>Monday - Friday: 8:00AM - 5:00PM<br></br></td>
              </tr>
              <tr>
                <td></td>
                <td><b>Calls/Texts/Email Replies ARE ASAP!!</b></td>
              </tr>

              <tr>
                <td id="table-cell-label">Address:</td>
                <td><a href="https://goo.gl/maps/v7rmMZTJwGiTZQ828">6582 Hwy 32 W, Douglas, GA 31533</a></td>
              </tr>

              <tr>
                <td id="table-cell-label">Email:</td>
                <td>Use our form button below!</td>
              </tr>

              <tr>
                <td></td>
                <td>...or email us at <a href="mailto:pyramidcargosolutions@gmail.com">pyramidcargosolutions@gmail.com</a></td>
              </tr>
            </tbody>
            
          </table>

          <DividerOverride orientation="horizontal" flexItem />

          <div id="misc">
            <Typography fontSize={"0.8em"}>Copyright &#169;2023 PyramidCargoSolutions</Typography>
            <Tooltip title="This website is powered for $12/year. Interested? Contact webmaster at swzy0000@gmail.com" enterTouchDelay={0} leaveTouchDelay={3000} placement="top" arrow>
              <Button><Typography fontSize={"0.8em"}>Powered by swzy</Typography></Button>
            </Tooltip>
          </div>
          
        </Box>

        <Box sx={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          borderTop: 0.25,
        }}>
        <BottomNavigation
          showLabels
          value={footerVal}
          onChange={(_event, newValue) => {
            setFooterValue(newValue);
          }}
          sx={{
            display: "flex",
            justifyContent: "space-around",
            backgroundColor: "gold"
          }}
        >
          <BottomNavigationAction 
          label="Call" 
          onClick={beginCall}
          icon={<PhoneIcon />} />
          <DividerOverride orientation="vertical" flexItem />
          <BottomNavigationAction 
          label="Maps" 
          onClick={linkToMaps}
          icon={<LocationOnIcon />} />
          <DividerOverride orientation="vertical" flexItem />
          <BottomNavigationAction 
          label="Email" 
          onClick={handleEmailOpen}
          icon={<EmailIcon />} />
          <DividerOverride orientation="vertical" flexItem />
          <BottomNavigationAction 
          label="Facebook" 
          onClick={openFacebook}
          icon={<FacebookIcon />} />
        </BottomNavigation>
      </Box>

      <EmailModal open={emailModalStatus} handleClose={handleEmailClose} />
      </div>
      
    );
  }

export default Footer;