import { Paper, Typography } from '@mui/material';

const TFKoalaCard = () => {
  return (
    <Paper
        sx={{
        borderTop: 1,
        borderColor: 'divider',
        padding: '1em',
        margin: '1.5em 0',
        minWidth: '300px'
        }}>

        <Typography
        variant='h4'
        >Koala Capital</Typography>

        <ul id='finance-app-list'>
            <li><a href="https://app.hellosign.com/s/6oDL8T4v">Click here to start your Koala Capital application</a></li>
        </ul>
    </Paper>
  )
}

export default TFKoalaCard