import { Paper, Typography } from '@mui/material';
import QR from '../../static/QR_Financing.png';
import RTO_app from '../../static/RTO_app.pdf';


const RTOHometowneCard = () => {
  return (
    <Paper
    sx={{
        borderTop: 3,
        borderColor: 'divider',
        padding: '1em',
        maxWidth: '800px'
    }}>

        <Typography
        variant='h4'
        >Hometowne RTO</Typography>

        <ul id='finance-app-list'>
        <li>Scan our QR code
            <br></br>
            <img
            src={QR}
            alt="qr code for hometowne finance"
            width={'150px'} />
        </li>
        -or-
        <li><a href="https://rtostreamline.com/mobile/Pioneertrailersrto/default.aspx?dealer_id=14&location_id=2680&county=Coffee">Click here to start your Hometowne RTO application</a></li>
        -or-
        <br></br>
        <li><a href={RTO_app} download>Download our Hometowne RTO application</a></li>
        </ul>

    </Paper>
  )
}

export default RTOHometowneCard;