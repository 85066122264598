import { Paper, Typography } from '@mui/material';

const TFRockSolidFundingCard = () => {
  return (
    <Paper
      sx={{
        borderTop: 1,
        borderColor: 'divider',
        padding: '1em',
        margin: '1.5em 0',
        minWidth: '300px'
      }}>

        <Typography
        variant='h4'
        >Rock Solid Funding</Typography>

        <ul id='finance-app-list'>
          <li><a href="https://www.rocksolidfunding.com/loan-app/?tfa_1171=2096&tfa_1174=pyramidcargosolutions">Click here to start your application with RSF</a></li>
        </ul>
    </Paper>
  )
}

export default TFRockSolidFundingCard