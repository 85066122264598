import { Paper, Typography } from '@mui/material';

const TFTrailerSolutionsFinancialCard = () => {
  return (
    <Paper
        sx={{
        borderTop: 1,
        borderColor: 'divider',
        padding: '1em',
        margin: '1.5em 0',
        minWidth: '300px'
        }}>
        <Typography
        variant='h4'
        >Trailer Solutions Financial</Typography>

        <ul id='finance-app-list'>
            <li><a href="https://trailersolutions-financial.com/credit_app/application/1092?fbclid=lwAR2Chozt6gHQO6e2q7kz7tfpHrpDvt__UnvlYLf-J2p13hv5oqSB8O6oo">Click here to start your application with TSF</a></li>
        </ul>

    </Paper>
  )
}

export default TFTrailerSolutionsFinancialCard