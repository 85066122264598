export const carProductData = [
    {
        "pics": [
            {
                "id": 1,
                "name": "Customizable Car Hauler",    
                "url": "https://pcs-trailer-products.s3.amazonaws.com/prod/7_20_TA_CAR.jpg",
                "description": "Transport your vehicles with confidence. Offers secure and reliable hauling, ensuring your prized cars reach their destination safely."
            },
            {
                "id": 2,
                "name": "Customizable Car Hauler",
                "url": "https://pcs-trailer-products.s3.amazonaws.com/prod/8_16_CAR.jpg",
                "description": "Transport your vehicles with confidence. Offers secure and reliable hauling, ensuring your prized cars reach their destination safely."
            },
            {
                "id": 3,
                "name": "Customizable Car Hauler",
                "url": "https://pcs-trailer-products.s3.amazonaws.com/prod/8_24_TA_CAR.jpg",
                "description": "Transport your vehicles with confidence. Offers secure and reliable hauling, ensuring your prized cars reach their destination safely."
            },
            {
                "id": 4,
                "name": "Customizable Car Hauler",
                "url": "https://pcs-trailer-products.s3.amazonaws.com/prod/8_30_CAR2.jpg",
                "description": "Transport your vehicles with confidence. Offers secure and reliable hauling, ensuring your prized cars reach their destination safely."
            },
            {
                "id": 5,
                "name": "Customizable Car Hauler",    
                "url": "https://pcs-trailer-products.s3.amazonaws.com/prod/8_34_CAR.jpg",
                "description": "Transport your vehicles with confidence. Offers secure and reliable hauling, ensuring your prized cars reach their destination safely."
            },
        ]
    }
]

export default carProductData;