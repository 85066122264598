import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Router
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import ErrorPage from "./routes/ErrorPage";
import Financing from './routes/Financing';
import Ordering from './routes/Ordering';
import Gallery from './routes/Gallery';
import Product from './routes/Product';
import Home from './routes/Home';


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        errorElement: <ErrorPage />,
        children: [
          { index: true, element: <Home /> },
          {
            path: "products",
            element: <Product />
          },
          {
            path: "financing",
            element: <Financing />
          },
          {
            path: "ordering",
            element: <Ordering />
          },
          {
            path: "gallery",
            element: <Gallery />
          },
          {
            path: "*",
            element: <ErrorPage />
          }
        ]
      }
    ]
  }
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider
      router={router}
    />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();