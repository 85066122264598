import Box from '@mui/material/Box';

const Banner = () => {
  return (
    <Box 
    sx={{
        display: "flex",
        justifyContent: "center",
        position: "fixed",
        width: "100%",
        padding: "0.25em",
        textAlign: "center",
        fontWeight: "600",
        backgroundColor: "gold",
        zIndex: 5
    }}
    >
        Need More Information? Call Us At&nbsp;912&#8209;393&#8209;1894
    </Box>
  )
}

export default Banner