import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';


const ProductModal = ({ productData, open, handleClose }) => {
  if (productData === null || productData === undefined) {
    return (
      <Modal
        sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}    
        keepMounted={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="product-image-increased-size"
        aria-describedby="product-image-increased-size"
      >
        <Card
        sx={{
            margin: "1em",
            padding: "0.25em"
        }}>
          <h4>Image Missing!</h4>
        </Card>
      </Modal>
    )
  }

  return (
    <div>
      <Modal
        sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}    
        keepMounted={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="product-image-increased-size"
        aria-describedby="product-image-increased-size"
      >
        <Card
        sx={{
            margin: "1em",
            padding: "0.25em"
        }}>
          <CardMedia
          id="product-modal-picture"
          component="img"
          height={"450em"}
          image={productData.url}
          alt={productData.name}
          />
        </Card>
      </Modal>
    </div>
  );
}

export default ProductModal;