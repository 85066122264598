import { Paper, Typography } from '@mui/material';
import QR from '../../static/hopkinsrto_qr.jpg';


const RTOHopkinsCard = () => {
  return (
    <Paper
    sx={{
        borderTop: 3,
        borderColor: 'divider',
        padding: '1em',
        margin: '1.5em 0',
        maxWidth: '800px'
    }}>

        <Typography
        variant='h4'
        >Hopkins RTO</Typography>

        <ul id='finance-app-list'>
        <li>Scan our QR code
            <br></br>
            <img
            src={QR}
            alt="qr code for hopkins finance"
            width={'150px'} />
        </li>
        -or-
        <li><a href="https://rtostreamline.com/mobile/Trailersrenttoown/default.aspx?dealer_id=53&location_id=263">Click here to start your Hopkins RTO application</a></li>
        </ul>

    </Paper>
  )
}

export default RTOHopkinsCard;