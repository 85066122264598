import Banner from './Banner';
import logo from '../static/pcslogo.svg';

export const Header = ( {companyName} ) => {
  return (
    <header>
        <div id="top-banner">
          <Banner />
          <div id="logo">
            <img src={logo} className="App-logo" alt="logo" />
          </div>
          <div id="company-name">{ companyName }</div>
          <div id="slogan">
            High Quality Trailers,
            Superior Customer Service
          </div>
          
        </div>
    </header>
  )
}

Header.defaultProps = {
    companyName: "Pyramid Cargo Solutions"
}

export default Header