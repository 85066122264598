import { Paper, Typography } from '@mui/material';
import RTO_app from '../../static/RBCRTOWORKSHEET.pdf';


const RTOBCCapitalCard = () => {
  return (
    <Paper
    sx={{
        borderTop: 3,
        borderColor: 'divider',
        padding: '1em',
        margin: '1.5em 0',
        maxWidth: '800px'
    }}>

        <Typography
        variant='h4'
        >BC Capital RTO</Typography>

        <ul id='finance-app-list'>
        <li><a href={RTO_app} download>Download our BC Capital RTO application</a></li>
        </ul>

    </Paper>
  )
}

export default RTOBCCapitalCard;