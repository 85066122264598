import './App.css';
import Header from './components/Header';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { useState } from "react";
import { Outlet } from "react-router-dom";

function App() {
  // eslint-disable-next-line
  const [userFavorites, setFavorite] = useState(null);

  return (
    <div className="main-body">
      <Header  />
      <Navbar/>      
      <Outlet />
      <Footer userFavorites={userFavorites}/>
    </div>
  );
}

export default App;
