import { Paper, Typography } from '@mui/material';

const TFClickLeaseCard = () => {
  return (
    <Paper
        sx={{
        borderTop: 1,
        borderColor: 'divider',
        padding: '1em',
        margin: '1.5em 0',
        minWidth: '300px'
        }}>

        <Typography
        variant='h4'
        >ClickLease</Typography>

        <ul id='finance-app-list'>
            <li><a href="https://app.clicklease.com/inlineapp?token=b9ce5412-8eb2-441f-92fb-c8cf81ccb45b">Click here to start your ClickLease application</a></li>
        </ul>
    </Paper>
  )
}

export default TFClickLeaseCard