// Static, hard-coded product data to support SPA-only website with fetch calls.

export const productData = [
    {
        "pics": [
            {
                "id": 1,
                "name": "5x8 Enclosed",
                "url": "https://pcs-trailer-products.s3.amazonaws.com/prod/5_8_SA.jpg",
                "description": "Designed to be secure, spacious, and reliable. Our trailer keeps your cargo protected and ensures hassle-free transport."
            }
        ]
    },
    {
        "pics": [
            {
                "id": 6,
                "name": "6x12 Dump",
                "url": "https://pcs-trailer-products.s3.amazonaws.com/prod/6_12_TA_DUMP.jpg",
                "description": "Effortlessly handle heavy loads with our rugged dump trailer. Ensure quick and efficient unloading!"
            },
            {
                "id": 1,
                "name": "6x10 Enclosed",
                "url": "https://pcs-trailer-products.s3.amazonaws.com/prod/6_10_TA.jpg",
                "description": "Designed to be secure, spacious, and reliable. Our trailer keeps your cargo protected and ensures hassle-free transport."
            },
            {
                "id": 2,
                "name": "6x10 Enclosed",
                "url": "https://pcs-trailer-products.s3.amazonaws.com/prod/6_10_TA2.jpg",
                "description": "Designed to be secure, spacious, and reliable. Our trailer keeps your cargo protected and ensures hassle-free transport."
            },
            {
                "id": 3,
                "name": "6x10 Utility",
                "url": "https://pcs-trailer-products.s3.amazonaws.com/prod/6_10_UTIL.jpg",
                "description": "Versatile and durable, our utility trailer is your perfect companion for hauling equipment and materials."
            },
            {
                "id": 4,
                "name": "6x12 Enclosed",
                "url": "https://pcs-trailer-products.s3.amazonaws.com/prod/6_12_TA.jpg",
                "description": "Designed to be secure, spacious, and reliable. Our trailer keeps your cargo protected and ensures hassle-free transport."
            },
            {
                "id": 5,
                "name": "6x12 Enclosed",
                "url": "https://pcs-trailer-products.s3.amazonaws.com/prod/6_12_TA2.jpg",
                "description": "Designed to be secure, spacious, and reliable. Our trailer keeps your cargo protected and ensures hassle-free transport."
            }
        ]
    },
    {
        "pics": [
            {
                "id": 7,
                "name": "7x14 Enclosed Polycore",
                "url": "https://pcs-trailer-products.s3.amazonaws.com/prod/7_14_TA_POLY.jpg",
                "description": "Lightweight and incredibly durable, it has advanced composite construction that is built to withstand any challenge you throw at it."
            },
            {
                "id": 1,
                "name": "7x12 Concession",
                "url": "https://pcs-trailer-products.s3.amazonaws.com/prod/7_12_SA_CONC.jpg",
                "description": "Combines functionality and style and highly customizable, serving up mouthwatering delights wherever you go."
            },
            {
                "id": 2,
                "name": "7x12 Concession",
                "url": "https://pcs-trailer-products.s3.amazonaws.com/prod/7_12_TA_CONC.jpg",
                "description": "Combines functionality and style and highly customizable, serving up mouthwatering delights wherever you go."
            },
            {
                "id": 3,
                "name": "7x16 Concession",
                "url": "https://pcs-trailer-products.s3.amazonaws.com/prod/7_16_CONC.jpg",
                "description": "Combines functionality and style and highly customizable, serving up mouthwatering delights wherever you go."
            },
            {
                "id": 4,
                "name": "7x12 Enclosed",
                "url": "https://pcs-trailer-products.s3.amazonaws.com/prod/7_12_TA.jpg",
                "description": "Designed to be secure, spacious, and reliable. Our trailer keeps your cargo protected and ensures hassle-free transport."
            },
            {
                "id": 5,
                "name": "7x14 Enclosed",
                "url": "https://pcs-trailer-products.s3.amazonaws.com/prod/7_14_LADDER.jpg",
                "description": "Designed to be secure, spacious, and reliable. Our trailer keeps your cargo protected and ensures hassle-free transport."
            },
            {
                "id": 6,
                "name": "7x14 Enclosed",
                "url": "https://pcs-trailer-products.s3.amazonaws.com/prod/7_14_TA.jpg",
                "description": "Designed to be secure, spacious, and reliable. Our trailer keeps your cargo protected and ensures hassle-free transport."
            },
            {
                "id": 8,
                "name": "7x16 Equipment",
                "url": "https://pcs-trailer-products.s3.amazonaws.com/prod/7_16_EQUIP.jpg",
                "description": "Built tough to handle heavy loads and designed for durability. The perfect solution for transporting machinery and gear."
            },
            {
                "id": 9,
                "name": "7x16 Enclosed",
                "url": "https://pcs-trailer-products.s3.amazonaws.com/prod/7_16_TA.jpg",
                "description": "Designed to be secure, spacious, and reliable. Our trailer keeps your cargo protected and ensures hassle-free transport."
            },
            {
                "id": 10,
                "name": "7x16 Enclosed",
                "url": "https://pcs-trailer-products.s3.amazonaws.com/prod/7_16_TA2.jpg",
                "description": "Designed to be secure, spacious, and reliable. Our trailer keeps your cargo protected and ensures hassle-free transport."
            },
            {
                "id": 11,
                "name": "7x16 Enclosed",
                "url": "https://pcs-trailer-products.s3.amazonaws.com/prod/7_16_TA3.jpg",
                "description": "Designed to be secure, spacious, and reliable. Our trailer keeps your cargo protected and ensures hassle-free transport."
            },
            {
                "id": 12,
                "name": "7x16 Enclosed",
                "url": "https://pcs-trailer-products.s3.amazonaws.com/prod/7_16_TA4.jpg",
                "description": "Designed to be secure, spacious, and reliable. Our trailer keeps your cargo protected and ensures hassle-free transport."
            },
            {
                "id": 13,
                "name": "7x16 Work",
                "url": "https://pcs-trailer-products.s3.amazonaws.com/prod/7_16_TA_WORK.jpg",
                "description": "Get the job done efficiently with our reliable work trailer. Designed for heavy-duty tasks, it's equipped to carry tools and equipment."
            },
            
        ]
    },
    {
        "pics": [
            {
                "id": 1,
                "name": "8x15 Concession",
                "url": "https://pcs-trailer-products.s3.amazonaws.com/prod/8_15_TA_CONC.jpg",
                "description": "Combines functionality and style and highly customizable, serving up mouthwatering delights wherever you go."
            },
            {
                "id": 3,
                "name": "8x16 Enclosed",
                "url": "https://pcs-trailer-products.s3.amazonaws.com/prod/8_16_TA.jpg",
                "description": "Designed to be secure, spacious, and reliable. Our trailer keeps your cargo protected and ensures hassle-free transport."
            },
            {
                "id": 4,
                "name": "8x16 Concession",
                "url": "https://pcs-trailer-products.s3.amazonaws.com/prod/8_16_TA_CONC.jpg",
                "description": "Combines functionality and style and highly customizable, serving up mouthwatering delights wherever you go."
            },
            {
                "id": 5,
                "name": "8x20 Enclosed",
                "url": "https://pcs-trailer-products.s3.amazonaws.com/prod/8_20.jpg",
                "description": "Designed to be secure, spacious, and reliable. Our trailer keeps your cargo protected and ensures hassle-free transport."
            },
            {
                "id": 6,
                "name": "8x20 Concession",
                "url": "https://pcs-trailer-products.s3.amazonaws.com/prod/8_20_TA_CONC.jpg",
                "description": "Combines functionality and style and highly customizable, serving up mouthwatering delights wherever you go."
            },
            {
                "id": 7,
                "name": "8x22 Enclosed",
                "url": "https://pcs-trailer-products.s3.amazonaws.com/prod/8_22_TA.jpg",
                "description": "Designed to be secure, spacious, and reliable. Our trailer keeps your cargo protected and ensures hassle-free transport."
            },
            
        ]
    }
];

export default productData;