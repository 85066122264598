import Box from '@mui/material/Box';
import { Link } from "react-router-dom";

export default function ErrorPage() {
  return (
    <Box
    display={"flex"}
    flexDirection={"column"}
    alignItems={"center"}
    padding={"0.5em"}
    margin={"3em"}>
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <Link to="/">Go Back Home</Link>
    </Box>
  );
}