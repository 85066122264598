// Boilerplate. No system set up to easily upload photos
// and no images that fit the criteria.

import { useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import CircularProgress from '@mui/material/CircularProgress';

export const Gallery = () => {
  const [productImages, loadData] = useState(null);

  useMemo(() => {
    if (productImages === undefined || productImages === null) {
      fetch('https://jsonplaceholder.typicode.com/photos?_page=1&_limit=50')
        .then(response => response.json())
        .then(json => {
            loadData(json);
        })
        .catch((err) => {
            console.log('Fetch error:', err)
        });
    }
  }, [productImages])

  if (productImages === undefined || productImages === null) {
    return (
      <Box sx={{
        display: 'flex',
        padding: '3em',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <CircularProgress 
          size={'8em'}
        />
      </Box>
    )
  }

  return (
    <div>
      <Box
      sx={{ 
        margin: '1em',
        overflowY: 'scroll' 
      }}>
        <ImageList variant="masonry" cols={3} gap={8}>
          {productImages.map((item) => (
            <ImageListItem key={item.id}>
              <img
                src={`${item.url}?w=248&fit=crop&auto=format`}
                srcSet={`${item.url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </div>
  );
}

export default Gallery