export const concessionProductData = [
    {
        "pics": [
            {
                "id": 1,
                "name": "Customizable Concession Trailer",
                "url": "https://pcs-trailer-products.s3.amazonaws.com/prod/conc_side.jpg",
                "description": "Our specialty! Let your customers eat with their eyes as well as their mouths! Spacious and reliable, our trailers combine functionality with style."
            },
            {
                "id": 2,
                "name": "Customizable Concession Trailer",
                "url": "https://pcs-trailer-products.s3.amazonaws.com/prod/conc_rear_1.jpg",
                "description": "Our specialty! Let your customers eat with their eyes as well as their mouths! Spacious and reliable, our trailers combine functionality with style."
            },
            {
                "id": 3,
                "name": "Customizable Concession Trailer",
                "url": "https://pcs-trailer-products.s3.amazonaws.com/prod/conc_rear_2.jpg",
                "description": "Our specialty! Let your customers eat with their eyes as well as their mouths! Spacious and reliable, our trailers combine functionality with style."
            },
            {
                "id": 4,
                "name": "Customizable Concession Trailer",
                "url": "https://pcs-trailer-products.s3.amazonaws.com/prod/conc_inside_1.jpg",
                "description": "Our specialty! Let your customers eat with their eyes as well as their mouths! Spacious and reliable, our trailers combine functionality with style."
            },
            {
                "id": 5,
                "name": "Customizable Concession Trailer",
                "url": "https://pcs-trailer-products.s3.amazonaws.com/prod/conc_sink.jpg",
                "description": "Our specialty! Let your customers eat with their eyes as well as their mouths! Spacious and reliable, our trailers combine functionality with style."
            }
        ]
    }
]

export default concessionProductData;