import Modal from '@mui/material/Modal';
import { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import validator from 'validator';


export const EmailModal = ({ open, handleClose }) => {
    const [formNotValid, setInvalidForm] = useState(true);

    const [email, setEmail] = useState("");
    const [emailNotValid, setEmailInvalid] = useState(false);
    const [emailHelperText, setEmailHelperText] = useState("");

    const [name, setName] = useState("");
    const [nameNotValid, setNameInvalid] = useState(false);
    const [nameHelperText, setNameHelperText] = useState("");

    const [number, setNumber] = useState("");
    const [numberNotValid, setNumberInvalid] = useState(false);
    const [numberHelperText, setNumberHelperText] = useState("");

    const [message, setMessage] = useState("");
    const [messageNotValid, setMessageInvalid] = useState(false);
    const [messageHelperText, setMessageHelperText] = useState("");
    
    const validateForm = () => {
        if (emailNotValid || nameNotValid || messageNotValid || email.length < 1 || name.length < 1 || message.length < 1) {
            setInvalidForm(true);
        } else {
            setInvalidForm(false);
        }
    }

    useEffect(() => {
        validateForm();
    // eslint-disable-next-line
    }, [email, name, number, message]);

    const handleNameUpdate = (e) => {
        if (e.target.value.match(/^[A-Za-z\s]{1,20}$/i)) {
            setName(e.target.value)
            setNameInvalid(false)
            setNameHelperText("")
                        
        } else {
            setNameInvalid(true);
            setName("");
            setNameHelperText("Invalid name");
        }
    }
    const handleEmailUpdate = (e) => {
        if (validator.isEmail(e.target.value)) {
            setEmail(e.target.value);
            setEmailInvalid(false);
            setEmailHelperText("");
        } else {
            setEmailInvalid(true);
            setEmail("");
            setEmailHelperText("Invalid email")
        }
    }
    const handleNumberUpdate = (e) => {
        if (validator.isMobilePhone(e.target.value.replace('-', '')) || e.target.value === "") {
            setNumber(e.target.value);
            setNumberInvalid(false);
            setNumberHelperText("");
        } else {
            setNumberInvalid(true);
            // Optional, but if they provide one it should be a valid format
            setNumberHelperText("Optional number is invalid!");
        }
    }
    const handleMessageUpdate = (e) => {
        if (e.target.value !== "") {
            setMessage(e.target.value);
            setMessageInvalid(false);
            setMessageHelperText("");
        } else {
            setMessageInvalid(true);
            setMessage("");
            setMessageHelperText("Please leave a valid message");
        }
    }

    return (
        <div>
            <Modal
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                keepMounted={true}
                open={open}
                onClose={handleClose}
                aria-labelledby="email-contact-modal"
                aria-describedby="email-contact-description"
            >
                <Box
                    backgroundColor="white"
                    textAlign={"center"}
                    padding="2em"
                    sx={{
                        '& .MuiTextFieldRoot': { xs: 1, m: 1, width: '25ch' },
                        border: '2px solid black'
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div>
                        Send us an email and we'll get back to you shortly!
                    </div>
                    <div>
                        <form 
                        name="contact" 
                        method="POST" 
                        data-netlify="true"
                        data-netlify-honeypot="bot-field">
                            <input type="hidden" name="form-name" value="contact" />
                            <input type="hidden" name="subject" value={`PCS Website - Inquiry from ${name}`} />
                            <TextField
                            onBlur={handleEmailUpdate}
                            error={emailNotValid}
                            id="email"
                            name="email"
                            margin="dense"
                            label="Your Email"
                            placeholder="somebody@email.com"
                            helperText={emailHelperText}
                            />
                            <TextField
                            onBlur={handleNameUpdate}
                            error={nameNotValid}
                            id="name"
                            name="name"
                            margin="dense"
                            label="Name"
                            placeholder="John Adams"
                            helperText={nameHelperText}
                            />
                            <TextField
                            onBlur={handleNumberUpdate}
                            error={numberNotValid}
                            id="number"
                            name="number"
                            margin="dense"
                            label="Number (optional)"
                            placeholder="123-456-7890"
                            helperText={numberHelperText}
                            />
                            <TextField
                            onBlur={handleMessageUpdate}
                            error={messageNotValid}
                            id="message"
                            name="message"
                            margin="dense"
                            label="What do you need?"
                            placeholder="I am looking for an open-back trailer!"
                            helperText={messageHelperText}
                            multiline
                            />
                            <Button 
                            sx={{
                                marginTop:"1em"
                            }}
                            variant="contained" 
                            color="success"
                            type="submit"
                            disabled={formNotValid}>
                                Send Email
                            </Button>
                        </form> 
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default EmailModal;