import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import ProductModal from './modal/ProductModal';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);


const ProductCard = ( {data} ) => {
  // Modal states
  const [currentModalSubject, setModalSubject] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const handleOpen = (data) => {
    setModalSubject(data);
    setModalOpen(true);
  }
  const handleClose = () => {
    setModalSubject(null);
    setModalOpen(false);
  }

  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const productPics = data["pics"];
  const maxSteps = productPics.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <div>
      <Card sx={{
        className: "product-card-container",
        maxWidth: 500,
        margin: "0.5em 1em"
      }}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {productPics[activeStep].name}
          </Typography>
          <Typography variant="body2" color="text.secondary" textAlign={"left"} minHeight={"65px"} maxWidth={"85%"}>
            {productPics[activeStep].description}
          </Typography>
        </CardContent>

      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        interval={5000}
      >
        {productPics.map((step, index) => (
          <div key={step.id}>
            {Math.abs(activeStep - index) <= 2 ? (
              <CardActionArea
                onClick={() => handleOpen(step)}
                key={index}
              >
                <Box
                  component="img"
                  sx={{
                    height: 400,
                    display: 'block',
                    maxWidth: 345,
                    overflow: 'hidden',
                    width: '100%',
                  }}
                  src={step.url}
                  alt={step.name}
                />
              </CardActionArea>
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Card>

    <ProductModal
    productData={currentModalSubject}
    open={modalOpen}
    handleClose={handleClose}
    setModalSubject={setModalSubject}/>
    </div>
  );
}

export default ProductCard